.page-error {
  padding-top: 83px;
}

.error-emoji {
  display: block;
  color: rgba(0,0,0,.4);
  font-family: $monospace;
  min-width: 680px;
  font-size: 150px;
  text-align: center;
}

.error-text {
  color: rgba(0,0,0,.4);
  line-height: 21px;
  margin-top: 60px;
  font-family: $monospace;
  white-space: pre-wrap;
  text-align: center;
}


/******************************************************************************/
/* minimal mistakes CSS overrides                                             */
/******************************************************************************/
.page__hero {

  &--overlay {

    .page__title__centered {
      @extend .page__title;

      font-size: $type-size-2*2.1;

      @include breakpoint($small) {
        font-size: $type-size-1*1.5;
      }

      text-align: center;
    }

    .page__lead__centered {
      @extend .page__lead;

      max-width: 100%;
      text-align: center;
      font-size: $type-size-4*1.2;
    }
  }
}

.site-logo svg {
  height: 2.75rem;
}

.masthead__inner-wrap {
  padding: 0.75em 1em 0.25em 1em;
}

.masthead__menu-item {
  font-size: $type-size-5*1.1;
}

/******************************************************************************/
/* SSEC own CSS classes                                                       */
/******************************************************************************/

// our page inherits everything from Minimal mistakes' page but expands 
// to 100% of its container
.ssec_page {
  @extend .page;

  width: 100%;
  padding-right: 0px;
}

.ssec__archive__item {
  display: flex;
  flex-direction: row;
  position: relative;

  &:not(:last-child) {
    border-bottom: solid 1px $border-color;
  }

  a {
    position: relative;
    z-index: 10;
  }

  a[rel="permalink"] {
    position: static;
  }

  &-title {
    margin-bottom: 0.25em;

    font-family: $sans-serif-narrow;
    line-height: initial;
    overflow: hidden;
    text-overflow: ellipsis;

    a[rel="permalink"]::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }

    a + a {
      opacity: 0.5;
    }
  }

  &-teaser {
    max-width: 30%;
    vertical-align: middle;
    text-align: center;
    margin-top: 2em;
    margin-bottom: auto;
    padding-top: 0;
    padding-left: 2.5%;
    padding-right: 2.5%;
    padding-bottom: 4%;

    img {
      display: block;
      margin: 0 auto;
    }

    @include breakpoint(0px $tiny) {
      display: block;
      margin: 0 auto;
      width: 65%;
      max-width: 100%;
    }
  }

  &-meta {
    float: right;
    margin: 0 0 4px;
  }


  /* single column display for phones */
  @include breakpoint(0px $tiny) {
    flex-direction: column;
  }
}

.page__content {

  /* justify text by default */
  text-align: justify;

    /* remove border*/
  .ssec__archive__item-title {
    margin-top: 1em;
    border-bottom: none;
  }
}

/* we don't want justified text in the sidebar */
.sidebar__right {
  text-align: left;
}

.ssec__figure {
  display: table;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;

  img {
    width: 100%;
    border-radius: $border-radius;
    -webkit-transition: $global-transition;
    transition: $global-transition;
    max-width: 100%;
    margin-bottom: 1em;
    vertical-align: middle;
    box-shadow: 0 0 10px rgba(0,0,0,0.25);
  }

  &.half_right {

    float: right;

    @at-root #{&} {
      margin: 0.4em 0 0 1em; 
    }

    @include breakpoint($small) {
      width: calc(50% - 0.5em);
    }

    figcaption {
      text-align: right;
    }
  }

  &.half_left {
    float: left;

    @at-root #{&} {
      margin: 0.4em 1em 0 0; 
    }

    @include breakpoint($small) {
      width: calc(50% - 0.5em);
    }

    figcaption {
      text-align: left;
    }
  }

  figcaption {
    display: table-caption;
    caption-side: bottom;
    font-size: $type-size-6*1.25;
    font-style: italic;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 320px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
